<template>
  <div class="leaderboard bg-black overflow-scroll pb-20" v-if="leaderboard">
    <div class="flex justify-between items-center mt-10 px-10 w-full">
      <div class="flex justify-start">
        <img :src="playdaLogo" class="h-20 w-auto" />
      </div>
      <div class="flex justify-end">
        <img :src="leaderboardLogo" class="h-20 w-auto" />
      </div>
    </div>
    <hr class="border-b border-nColorBGDarkGray my-4" />
    <div class="flex w-full justify-between pt-4 px-10 items-start">
      <div class="flex items-start gap-4">
        <div>
          <img
            :src="
              leaderboard[0].tiles_base_path +
              leaderboard[0].meta.slug +
              '.webp'
            "
            class="h-32 w-auto"
          />
        </div>
        <div
          class="font-ManropeMedium font-bold text-nColorYellow text-shadow-black-right"
        >
          <div class="text-5xl">
            {{ leaderboard[0].meta.name }}
          </div>
          <div v-if="leaderboardDatetime" class="text-xl mt-4">
            {{ getGameStatusMessage() }}
          </div>
        </div>
      </div>
      <div>
        <p
          class="font-InterSemiBold text-nColorYellow text-xl text-shadow-black-right"
        >
          {{ formattedGamePlays }}
        </p>
        <p
          class="font-InterSemiBold text-nColorYellow text-xl text-shadow-black-right"
          v-if="isEmptyObject(closedGame)"
        >
          {{ formattedLeaderboardCount }}
        </p>
      </div>
    </div>
    <hr class="border-b border-nColorBGDarkGray my-4" />
    <div class="flex w-full justify-between px-10 items-center">
      <div
        v-if="
          leaderboard[0].details.reward_partners &&
          leaderboard[0].details.reward_partners.length > 0
        "
      >
        <p
          class="font-ManropeMedium font-bold text-nColorYellow text-shadow-black-right"
        >
          Reward Partners
        </p>
        <div class="flex gap-2 mt-2">
          <div
            v-for="partner in leaderboard[0].details.reward_partners"
            :key="partner"
          >
            <div>
              <img
                :src="partnerImage(partner)"
                alt="Reward Partners"
                class="w-20 h-auto"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="font-InterSemiBold text-nColorYellow text-xl text-shadow-black-right"
      >
        {{ formattedCurrentDateTime }}
      </div>
    </div>
    <hr class="border-b border-nColorBGDarkGray my-4" />
    <div id="leaderboardContent" class="px-10">
      <div class="text-3xl mt-6 text-nColorYellow">Leaderboard</div>
      <div v-if="!isEmptyObject(liveGame)" class="pb-10">
        <Results
          :leaderboard="liveGame.leaderboard"
          :gameLive="liveGame.game_live"
          class="pt-2"
        />
      </div>
      <div v-else-if="!isEmptyObject(leaderboardGame)" class="pb-10">
        <Results
          :leaderboard="leaderboardGame.leaderboard"
          :gameLive="leaderboardGame.game_live"
          class="pt-2"
        />
      </div>
      <div v-else-if="!isEmptyObject(closedGame)" class="pb-10">
        <Results
          :leaderboard="closedGame.leaderboard"
          :gameLive="closedGame.game_live"
          class="pt-2"
        />
      </div>
      <div
        v-else
        class="font-InterSemiBold text-white text-xl text-shadow-black-right flex justify-center py-10 border border-nColorLabelGray mt-2 rounded-3xl"
      >
        Leaderboard not populated as yet
      </div>
    </div>
    <div class="w-full fixed bottom-0 left-0">
      <hr class="border-b border-nColorBGDarkGray my-4" />
      <div class="flex w-full justify-between px-10 pt-4 pb-10 items-center">
        <div
          class="font-InterSemiBold text-nColorYellow text-6xl text-shadow-black-right"
        >
          Scan, Play & Win
        </div>
        <div>
          <img :src="leaderboard[0].qr" class="h-40" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Results from "@/components/Leaderboard/Results.vue";
import slugs from "@/common/slugs";

export default {
  components: { Results },
  data() {
    return {
      business: this.$route.params.business,
      leaderboard: null,
      liveGame: {},
      leaderboardGame: {},
      leaderboardDatetime: null,
      closedGame: {},
      gamePlays: 0,
      leaderboardCount: 0,
      slugs,
      playdaLogo: process.env.VUE_APP_PLAYDA_LOGO_URL,
      leaderboardLogo: null,
      gameEnds: null,
      currentDate: new Date(),
    };
  },
  created() {
    this.getLeaderboardData();
  },
  mounted() {
    this.interval = setInterval(() => {
      this.getLeaderboardData();
      console.log("updated");
    }, 5 * 60 * 1000);
  },
  methods: {
    getLeaderboardData() {
      ApiService.get(apiResource.getLeaderboard, {
        business_slug: this.$route.params.business,
        schedule_id: this.$route.params.schedule_id,
      }).then((data) => {
        this.leaderboard = data.data.data;
        this.leaderboardLogo = data.data.data[0].leaderboard_logo;
        this.gamePlays = data.data.data[0].total_gameplays;
        this.leaderboardCount =
          this.leaderboard[0].leaderboard.leaderboard_count;
        this.leaderboard.forEach((game) => {
          this.leaderboardDatetime = game.details.leaderboard_datetime;
          if (game.game_live) {
            this.liveGame = game;
          } else if (game.leaderboard_timer) {
            this.leaderboardGame = game;
          }
        });
      });
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0;
    },
    formatFriendlyTime(timeString) {
      const [hour, minute] = timeString.split(":");
      let period = "AM";

      let hourNum = parseInt(hour, 10);
      if (hourNum >= 12) {
        period = "PM";
        if (hourNum > 12) {
          hourNum -= 12;
        }
      }

      return `${hourNum}:${minute} ${period}`;
    },
    showTrophy(position, game) {
      if (position <= parseInt(game.details.no_of_winners)) {
        return true;
      }
      return false;
    },
    getGameStatusMessage() {
      const leaderboardDate = new Date(this.leaderboardDatetime);
      const currentDate = new Date();

      const timeString = leaderboardDate.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const dateString = leaderboardDate.toLocaleDateString(undefined, {
        weekday: "long",
        day: "numeric",
        month: "long",
      });

      if (currentDate < leaderboardDate) {
        return `Game closes at ${timeString} on ${dateString}`;
      } else {
        return `Game closed at ${timeString} on ${dateString}`;
      }
    },
    partnerImage(partner) {
      return (
        "https://playda.s3.ap-south-1.amazonaws.com/assets/brands/" +
        partner +
        ".png"
      );
    },
    addOrdinalSuffix(day) {
      if (day > 3 && day < 21) return `${day}th`;
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    },
  },
  computed: {
    formattedGamePlays() {
      // Format the number with "k" suffix if it's in the thousands
      if (this.gamePlays >= 1000) {
        return (this.gamePlays / 1000).toFixed(2) + "k Game Plays";
      } else {
        return this.gamePlays + " Game Plays";
      }
    },
    formattedLeaderboardCount() {
      if (this.leaderboardCount >= 1000) {
        return (this.leaderboardCount / 1000).toFixed(2) + "k Playing";
      } else {
        return this.leaderboardCount + " Playing";
      }
    },
    formattedCurrentDateTime() {
      const date = this.currentDate;

      // Format the time
      const time = date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

      // Get the day of the week and month
      const day = date.toLocaleString("en-US", { weekday: "long" });
      const month = date.toLocaleString("en-US", { month: "long" });

      // Get the day of the month with ordinal suffix
      const dayOfMonth = date.getDate();
      const dayWithSuffix = this.addOrdinalSuffix(dayOfMonth);

      return `${time}, ${day}, ${dayWithSuffix} ${month}`;
    },
  },
};
</script>

<style scoped lang="postcss">
.leaderboard {
  height: 100dvh;
}
@keyframes sparkle {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.sparkle-button {
  display: inline-block;
  animation: sparkle 0.75s infinite;
}
@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0deg);
  }
  50% {
    transform: perspective(400px) rotateY(90deg);
  }
  100% {
    transform: perspective(400px) rotateY(0deg);
  }
}

#flip-text {
  animation: flip 2s infinite;
}
.normal-row {
  border-bottom: 1px solid rgba(108, 119, 52, 0.5);
  padding: 8px;
  text-align: left;
}
.highlighted-row {
  @apply bg-nColorYellow;
  padding: 8px;
}
.highlighted-row td {
  padding: 8px;
}
.you-text {
  border-bottom: 1px solid rgba(108, 119, 52, 0.5);
  color: black;
}
</style>
