<template>
  <div>
    <div
      v-if="leaderboard.results.length === 0"
      class="text-nColorLBLightGray text-center mt-2"
    >
      <p>No one has played as yet.</p>
    </div>
    <div v-else>
      <div v-for="(result, index) in leaderboard.results" :key="result">
        <div
          class="flex justify-between items-center gap-2 text-white rounded-xl p-3 border border-nColorYellow mt-2"
        >
          <div class="w-10 text-center">
            <img
              :src="getPositionImage(index + 1)"
              :alt="'Position' + index + 1"
              width="48"
              height="54"
              v-if="index < 3"
            />
            <div
              v-else
              class="text-nColorGreen font-bold font-InterSemiBold text-xl"
            >
              <p>{{ index + 1 }}</p>
            </div>
          </div>
          <div class="w-3/6">
            <p class="font-InterSemiBold text-2xl">{{ result.player }}</p>
          </div>
          <div class="w-3/6 flex justify-start pr-6">
            <div
              class="bg-lColorBGGray p-0.5 rounded-xl flex items-center gap-10"
            >
              <div class="flex gap-2 items-center justify-start w-32">
                <div>
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/score.webp"
                    alt="Score"
                    width="20"
                    height="18"
                  />
                </div>
                <p class="font-InterSemiBold text-xl">
                  {{ result.highest_score.score }}
                </p>
              </div>
              <div
                class="flex items-center gap-2 mt-1"
                v-if="result.highest_score.time"
              >
                <div class="pl-1">
                  <img
                    src="https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/time-taken.webp"
                    alt="Time Taken"
                    width="20"
                    height="18"
                  />
                </div>
                <p class="font-InterSemiBold text-xl">
                  {{ timeTaken(result.highest_score.time) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    leaderboard: {
      type: Object,
      required: true,
    },
    gameLive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    timeTaken(seconds) {
      let minutes = Math.floor(seconds / (1000 * 60));
      let extraSeconds = Math.floor((seconds % (1000 * 60)) / 1000);
      let milliseconds = Math.round(seconds % 1000);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      extraSeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;
      return minutes + ":" + extraSeconds + ":" + milliseconds;
    },
    getPositionImage(index) {
      return `https://playda.s3.ap-south-1.amazonaws.com/assets/leaderboard/position${index}.webp`;
    },
    toggleDetails(result, index) {
      if (result.is_customer) {
        this.customerResult = result;
        this.customerPosition = index;
        this.showDetails = true;
      }
    },
    closeDetails() {
      this.showDetails = false;
    },
  },
};
</script>

<style scoped lang="postcss"></style>
