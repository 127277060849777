export default {
  "scavenger-hunt": "scavengerHunt",
  "what-came-first": "whatCameFirst",
  "find-me": "findMe",
  "merge-faces": "mergeFaces",
  "make-words": "makeWords",
  "the-pouring-game": "thePouringGame",
  "doodle-match": "doodleMatch",
  memorize: "memorize",
  whacko: "whacko",
  scavengerHunt: "scavenger-hunt",
  jigsaw: "jigsaw",
  "true-false": "trueFalse",
  trueFalse: "true-false",
  gtm: "gtm",
  whatCameFirst: "what-came-first",
  findMe: "find-me",
  mergeFaces: "merge-faces",
  makeWords: "make-words",
  thePouringGame: "the-pouring-game",
  doodleMatch: "doodle-match",
  gameComponents: {
    scavengerHunt: "ScavengerHunt",
    jigsaw: "Jigsaw",
    trueFalse: "TrueOrFalse",
    gtm: "GTM",
    whatCameFirst: "WhatCameFirst",
    findMe: "FindMe",
    mergeFaces: "MergeFaces",
    memorize: "Memorize",
    whacko: "Whacko",
    makeWords: "MakeWords",
    thePouringGame: "ThePouringGame",
    doodleMatch: "DoodleMatch"
  }
};
